import { BaseController } from '../utils/stimulus'

/**
 * @class TabsController
 * Gestisce la visualizzazione delle tabs.
 */
export default class extends BaseController {

  static targets = [
    'content',
    'contentitem',
    'nav',
    'navitem'
  ]

  connect() {
    super.connect()
    this._goTo(0)
  }

  select(e) {
    e.preventDefault()
    const index = e.currentTarget.getAttribute('data-index')
    this._goTo(index)
  }

  _goTo(index) {
    this._onChange(index)
  }

  _onChange(index) {
    index = parseInt(index)

    this.contentitemTargets.forEach((item) => {
      const itemIndex = parseInt(item.getAttribute('data-index'))
      if (itemIndex === index) {
        item.classList.add('is-active')
      } else {
        item.classList.remove('is-active')
      }
    })

    this.navitemTargets.forEach((item) => {
      const itemIndex = parseInt(item.getAttribute('data-index'))
      if (itemIndex === index) {
        item.classList.add('is-active')
      } else {
        item.classList.remove('is-active')
      }
    })
  }


}
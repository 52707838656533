// import Chart from 'chart.js' // IMPORTATO DA CDN
import { BaseController } from '../utils/stimulus'

/**
 * @class ChartController
 */
export default class extends BaseController {

  connect() {
    this.database = JSON.parse(this.data.get('database'))

    this.chart = new Chart(this.element, {
      type: 'line',
      data: this._generateData(),
      options: {
        legend: {
          display: false
        }
      }
    })
  }

  _generateData() {
    const data = { datasets: [], labels: this.database.data.map((d) => d.x) }
    this.database.borderColor = '#f1c40f'
    this.database.backgroundColor = 'transparent'
    data.datasets.push(this.database)
    return data
  }

}

import { BaseController } from '../utils/stimulus'
import { postRequest, getRequest } from '../utils/requests'
import { clearCache } from '../utils/navigation'
import { getParentBySelector } from '../utils/dom'
import { locales } from '../utils/app'

/**
 * @class FormController
 * Gestisce i form in ajax e le relative richieste e risposte.
 */
export default class extends BaseController {

  static targets = [
    'input' // Identifica ogni singolo input da cui il form deve prendere il valore e gestire eventuali errori.
  ]

  connect() {
    super.connect()
    // identifico quale url utilizzare per effettuare l'eventuale richiesta ajax form.
    this.ajaxReqUrl = this.element.action
    // identifico quale funzione eseguire per effettuare l'eventuale richiesta ajax form.
    this.ajaxReqFunction = this.element.method === 'post' ? postRequest : getRequest
    // imposto ascoltatori sugli input per rimuovere eventuali errori.
    this.inputTargets.forEach((input) => input.addEventListener('change', () => this._findInputComponentContainer(input)?.classList.remove('is-error')))
  }

  /**
   * Prinicipale funzione di gestione dell'esecuzione di un form. 
   * NOTE: Al risultato della chiamata ajax decide il suo comportamento sulla base della configurazione
   * impostata tramite data-attribute onsuccess e onfail.
   */
  run(e) {
    if (e) e.preventDefault()

    this._sendAjaxRequest({}, (response) => {
      clearCache()

      if (response.result) {
        // gestisco redirect scelto dal server
        if (this.data.get('onsuccess') === 'redirect-from-response' && response.payload.redirect) {
          window.location.href = response.payload.redirect
          return
        }
        // gestisco redirect scelto dal dom
        if (this.data.get('onsuccess') === 'redirect-from-dom' && this.data.get('redirect')) {
          window.location.href = this.data.get('redirect')
          return
        }
        // gestisco flash scelto dal dom
        if (this.data.get('onsuccess') === 'flash-from-response') {
          this.getFlash().success(response.payload.flash, true)
          return
        }
        // gestisco flash scelto dal dom
        if (this.data.get('onsuccess') === 'flash-from-dom') {
          this.getFlash().success(this.data.get('flash'), true)
          return
        }
        // gestisco reload
        if (this.data.get('onsuccess') === 'reload') {
          window.location.reload()
          return
        }
      } else {
        // gestisco redirect scelto dal server
        if (this.data.get('onfail') === 'redirect-from-response' && response.payload && response.payload.redirect) {
          window.location.href = response.payload.redirect
          return
        }

        this.getFlash().error(response.errors[0]?.message ? response.errors[0].message : 'Si è verificato un errore', true)
      }
    })
  }

  /**
   * Funzione per eseguire il controllo degli input e segnalare eventuali problematiche.
   * NOTE: Da utilizzare su pulsnate di submit. Nel caso in cui gli input sono ok, il preventDefault() dell'evento
   * non viene eseguito.
   */
  checkInputs(e) {
    if (!this._validateInputParams()) {
      this.getFlash().error(locales()['general.strings.uncompleted_fields'], true)
      e.preventDefault()
    }
  }

  /**
   * @function _sendAjaxRequest
   * Invia una richiesta ajax per il form con eventuali parametri extra.
   * @param {*} extraParams 
   * @param {*} callback 
   */
  _sendAjaxRequest(extraParams = {}, callback = (response) => {}) {
    if (this._lockAjaxRequest) return
    this._lockAjaxRequest = true

    // identifico i parametri del form da inviare tramite richiesta ajax.
    let params = this._getInputParams()
    params = Object.assign({}, params, extraParams)

    // eseguo la richiesta ajax.
    this.ajaxReqFunction(this.ajaxReqUrl, params, (response) => {
      this.log(response)
      this._lockAjaxRequest = false
      callback(response)
    })
  }

  /**
   * @function _getParams
   * Ritorna i parametri degli input del form.
   */
  _getInputParams() {
    const params = {}

    this.inputTargets.forEach((input) => {
      if (!params[input.name]) params[input.name] = null

      if (input.type === 'checkbox') { // input checkbox
        if (input.checked) params[input.name] = input.value
      } else if (input.type === 'radio') { // input radio
        if (input.checked) params[input.name] = input.value
      } else { // input generico
        params[input.name] = input.value
      }
    })

    return params
  }
  
  /**
   * @function _validateInputParams
   * Esegue la validazione degli input e segnala eventuali errori.
   */
  _validateInputParams() {
    let valid = true

    this.inputTargets.forEach((input) => {
      if (!input.required) return

      if (input.type === 'radio' ) { // input radio
        // TODO: Se serve capire come gestire i radio obbligatori.
      } else if (input.type === 'checkbox' && !input.checked) { // input checkbox
        valid = false
        this._findInputComponentContainer(input)?.classList.add('is-error')
      } else if (!input.value) { // input generico
        valid = false
        this._findInputComponentContainer(input)?.classList.add('is-error')
      }
    })

    return valid
  }

  _findInputComponentContainer(input) {
    return getParentBySelector(input, '.c-input')
  }

}

import { BaseController } from '../utils/stimulus'
import { getRequest, postRequest } from '../utils/requests'

/**
 * @class FeedviewerController
 */
export default class extends BaseController {

  static targets = [
    'player',
    'loading'
  ]

  connect() {
    this._removeLoading()
  }

  interactionLove(e) {
    e.preventDefault()
    const element = e.currentTarget

    postRequest(this.data.get('interationloveurl'), { feed_uuid: this.data.get('feed') }, (response) => {
      if (!response.result) return
      this._updateCounterAfterInteraction(element, response.payload.counter)
    })
  }

  interactionFavorite(e) {
    e.preventDefault()
    const element = e.currentTarget

    postRequest(this.data.get('interationfavoriteurl'), { feed_uuid: this.data.get('feed') }, (response) => {
      if (!response.result) return
      this._updateCounterAfterInteraction(element, response.payload.counter)
    })
  }

  // BACKUP FUNZIONE IN CASO DI GESTIONE AJAX DEL CARICAMENTO DEL PLAYER.
  // _loadPlayer() {
  //   getRequest(this.data.get('playerurl'), { feed_uuid: this.data.get('feed') }, (response) => {
  //     if (!response.result) return // NOTE: Qui andrebbe gestito un messaggio se il contenuto non può essere caricato.

  //     this.playerTarget.innerHTML = response.payload.template
  //     this._removeLoading()
  //   })
  // }

  _removeLoading() {
    if (this.hasLoadingTarget) this.loadingTarget.classList.remove('is-active')
  }

  _updateCounterAfterInteraction(element, newValue) {
    element.classList.toggle('is-active')
    element.querySelector('.value').innerHTML = newValue
  }
 
}

/**
 * @function createElement
 */
export function createElement(tag, classes = '', content = '') {
  const el = document.createElement(tag)
  el.setAttribute('class', classes)
  el.innerHTML = content
  return el
}

/**
 * @function getParentBySelector
 * @param {*} elem 
 * @param {*} selector 
 */
export function getParentBySelector (elem, selector) {
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if ( elem.matches( selector ) ) return elem
	}
	return null
}

/**
 * @function isMobile
 */
export function isMobile () {
  return !window.matchMedia("(min-width: 850px)").matches
}
import { BaseController } from '../utils/stimulus'
import { enableScroll, disableScroll } from '../utils/helpers'

/**
 * @class NavigatorController
 */
export default class extends BaseController {

  static targets = [
    'filters',
    'filter',
    'select'
  ]

  toggleFilters(e) {
    e.preventDefault()
    this.filtersTarget.classList.toggle('is-open')

    if (this.filtersTarget.classList.contains('is-open')) {
      disableScroll()
    } else {
      enableScroll()
    }
  }

  selectFilter(e) {
    e.preventDefault()
    const filter = e.currentTarget.getAttribute('data-filter')

    this.selectTargets.forEach((selectTarget) => {
      if (selectTarget.getAttribute('data-filter') == filter) {
        selectTarget.classList.add('is-active')
      } else {
        selectTarget.classList.remove('is-active')
      }
    })

    this.filterTargets.forEach((filterTarget) => {
      if (filterTarget.getAttribute('data-filter') == filter) {
        filterTarget.classList.add('is-active')
      } else {
        filterTarget.classList.remove('is-active')
      }
    })
  }


}
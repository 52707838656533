import "@hotwired/turbo-rails"

// Inizializzo service worder.
if (navigator.serviceWorker) {
  window.addEventListener('load', () => {
    // navigator.serviceWorker.register('/serviceworker.js').then(function(reg) {
    //   console.log('[Page] Service worker registered!')
    // })
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
        registration.unregister()
      }
    })
  })
}

// Inizializzo funzione di tracking eventi con analytics
window.gtrack = (event, event_category = null, event_label = null, value = null) => {  console.log(event, `${event_category} - ${event_label} - ${value}`); if (typeof gtag == 'function') gtag('event', event, { event_category, event_label, value }) }

/**
 * Importazione dipendenze.
 */
// import LazyLoad from 'vanilla-lazyload' // IMPORTATO DA CDN
// import tippy from 'tippy.js' // IMPORTATO DA CDN
import { env, version } from './application/utils/app'
import { application } from './application/utils/stimulus'
import FormController from './application/controllers/FormController'
import LinkController from './application/controllers/LinkController'
import FileloaderController from './application/controllers/FileloaderController'
import SliderController from './application/controllers/SliderController'
import StoryviewerController from './application/controllers/StoryviewerController'
import TabsController from './application/controllers/TabsController'
import BottombarController from './application/controllers/BottombarController'
import HeaderController from './application/controllers/HeaderController'
import FeedgridController from './application/controllers/FeedgridController'
import FeedviewerController from './application/controllers/FeedviewerController'
import NavigatorController from './application/controllers/NavigatorController'
import VideoplayerController from './application/controllers/VideoplayerController'
import InputtaxonomiesController from './application/controllers/InputtaxonomiesController'
import FlashController from './application/controllers/FlashController'
import ChartController from './application/controllers/ChartController'
import AccordionsController from './application/controllers/AccordionsController'
import ChatController from './application/controllers/ChatController'
import CopytoController from './application/controllers/CopytoController'

/**
 * Registrazione controllers.
 */
application.register('form', FormController)
application.register('link', LinkController)
application.register('fileloader', FileloaderController)
application.register('slider', SliderController)
application.register('storyviewer', StoryviewerController)
application.register('tabs', TabsController)
application.register('bottombar', BottombarController)
application.register('header', HeaderController)
application.register('feedgrid', FeedgridController)
application.register('feedviewer', FeedviewerController)
application.register('navigator', NavigatorController)
application.register('videoplayer', VideoplayerController)
application.register('inputtaxonomies', InputtaxonomiesController)
application.register('flash', FlashController)
application.register('chart', ChartController)
application.register('accordions', AccordionsController)
application.register('chat', ChatController)
application.register('copyto', CopytoController)

/**
 * Esecuzione iniziale javascript.
 */
document.addEventListener('DOMContentLoaded', () => {
  console.log(`%cRunning on "${env()}" - v.${version()}`, 'color: #f1c40f; font-size: 18px')

  // Disabilito il tasto destro del mouse.
  // if (env() === 'production') document.addEventListener('contextmenu', event => event.preventDefault())

  // Inizializzo libreria di gestione lazyload.
  window.$lazyload = new LazyLoad({ use_native: true, elements_selector: '[lazy]' })

  // Inizializzo array dove memorizzare intervals.
  window.$intervals = []

  // Inizializzo tippy per tooltip
  tippy('[data-tippy-content]')

  // Shame
  gestioneInputComparsa()
})

document.addEventListener('turbo:load', () => {
  // Aggiorno lazyload immagini
  if (window.$lazyload) window.$lazyload.update()

  // pulisco eventuali interval lasciati in giro
  if (window.$intervals) window.$intervals.map((interval) => clearInterval(interval)); window.$intervals = [] 

  // Inizializzo tippy per tooltip
  tippy('[data-tippy-content]')

  // Shame
  gestioneInputComparsa()
})

// Shame
////////////////////////////////////////////////////////////////////////////////

function gestioneInputComparsa() {
  let inputVisibility = document.querySelector('[data-input-comp-visibility]')
  let inputPrice = document.querySelector('[data-input-comp-input]')
  let inputPriceContainer = document.querySelector('[data-input-comp-input-container]')
  if (inputVisibility) {
    inputVisibility.addEventListener('change', (e) => {
      if (e.target.checked || e.target.value == 'for_fee') {
        inputPrice.removeAttribute('disabled')
        inputPriceContainer.style.display = "block"
      } else {
        inputPrice.setAttribute('disabled', true)
        inputPriceContainer.style.display = "none"
      }
    })
  }
}

import { BaseController } from '../utils/stimulus'
import { postRequest, getRequest } from '../utils/requests'

/**
 * @class LinkController
 * Gestisce i link e i loro relativi controlli.
 */
export default class extends BaseController {

  connect() {
    super.connect()
    // identifico quale url utilizzare per effettuare l'eventuale richiesta ajax form.
    this.ajaxReqUrl = this.element.href
    // identifico quale funzione eseguire per effettuare l'eventuale richiesta ajax form.
    this.ajaxReqFunction = this.data.get('method') && this.data.get('method') == 'get' ? getRequest : postRequest
  }

  /**
   * Principale funzione di gestione dell'esecuzione di un link.
   */
  run(e) {
    e.preventDefault()

    this._manageConfirmations((result) => {
      if (!result) return

      this._sendAjaxRequest({}, (response) => {
        if (response.result) {
          // gestisco reload della pagina
          if (this.data.get('onsuccess') === 'reload') {
            window.location.reload()
            return
          }
          // gestisco redirect scelto dal dom
          if (this.data.get('onsuccess') === 'redirect-from-dom' && this.data.get('redirect')) {
            window.location.href = this.data.get('redirect')
            return
          }
        } else {
          // TODO: Mettere opzioni di gestione risposte negative
        }
      })
    })
  }

  /**
   * @function _manageConfirmations
   * Gestisce le richieste di conferma di esecuzione del link.
   */
   _manageConfirmations(callback = (result) => {}) {
     if (!this.data.get('confirmation')) return callback(true)

      const confirmation = window.confirm(this.data.get('confirmation'))
      callback(confirmation)
   }

   /**
    * @function _sendAjaxRequest
    */
  _sendAjaxRequest({}, callback = (response) => {}) {
    if (this._lockAjaxRequest) return
    this._lockAjaxRequest = true

    this.ajaxReqFunction(this.ajaxReqUrl, {}, (response) => {
      this.log(response)
      this._lockAjaxRequest = false
      callback(response)
    })
  }

}

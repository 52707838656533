import { BaseController } from '../utils/stimulus'
import { debounce } from '../utils/helpers'
import { getRequest } from '../utils/requests'

/**
 * @class InputtaxonomiesController
 * Gestisce gli input di inserimento e selezione tassonomie.
 */
export default class extends BaseController {

  static targets = [
    'input',
    'resultsBlock',
    'resultsContent',
    'selectedBlock',
    'selectedContent'
  ]

  connect() {
    this._searchOnInputChange = debounce(this._searchOnInputChange, 300)
    this.selectedValues = this.inputTarget.value ? this.inputTarget.value.split(',') : []

    this._loadResults()
    this._showSelected()
  }

  searchOnInputChange(e) {
    e.preventDefault()
    this._searchOnInputChange(e.target.value)
  }

  toggleTag(e) {
    e.preventDefault()
    this._toggleTag(e.currentTarget.getAttribute('data-value'))
  }

  /**
   * Gestisce la ricerca in base al cambio valore di un input.
   * @param {*} value 
   */
  _searchOnInputChange(value) {
    this._loadResults(value)
  }

  /**
   * Esegue il toggle di un tag tra quelli selezionati.
   * @param {*} value 
   */
  _toggleTag(value) {
    if (this.selectedValues.includes(value)) {
      this.selectedValues = this.selectedValues.filter((v) => v !== value)
    } else {
      this.selectedValues.push(value)
    }

    this.inputTarget.value = this.selectedValues.join(',')
    this._showSelected()
    this._updateSelected()
  }

  /**
   * Esegue il caricamento dei dati delle tassonomie.
   * @param {*} search 
   */
  _loadResults(search = null) {
    if (this._searchRunning) return
    this._searchRunning = true

    const params = {}
    if (search) params.search = search

    getRequest(this.data.get('searchurl'), params, (response) => {
      this._searchRunning = false
      if (!response.result) return
      this._showResults(response.payload.taxonomies)
    })
  }

  /**Visualizza i dati
   * 
   * @param {*} data 
   */
  _showResults(data) {
    if (this.hasResultsContentTarget) {
      if (data && data.length) {
        let template = ''
        data.filter((d) => !this.selectedValues.includes(d)).map((t) => { template += this._templateTag(t) })
        this.resultsContentTarget.innerHTML = template
      } else {
        this.resultsContentTarget.innerHTML = this._templateEmpty(this.data.get('resultsempty'))
      }
    }

    if (this.hasResultsBlockTarget) {
      if (data && data.length) {
        this.resultsBlockTarget.style.display = 'block'
      } else {
        if (!this.data.get('resultsempty')) this.resultsBlockTarget.style.display = 'none'
      }
    }
  }

  /**
   * Gestisce la visualizzazione dei tag selezionati.
   */
  _showSelected() {
    const data = this.selectedValues

    if (this.hasSelectedContentTarget) {
      if (data && data.length) {
        let template = ''
        data.map((t) => { template += this._templateTag(t) })
        this.selectedContentTarget.innerHTML = template
      } else {
        this.selectedContentTarget.innerHTML = this._templateEmpty(this.data.get('selectedempty'))
      }
    }

    if (this.hasSelectedBlockTarget) {
      if (data && data.length) {
        this.selectedBlockTarget.style.display = 'block'
      } else {
        if (!this.data.get('selectedempty')) this.selectedBlockTarget.style.display = 'none'
      }
    }
  }

  /**
   * Gestisce la stile dei tag selezionati.
   */
  _updateSelected() {
    if (this.hasResultsContentTarget) {
      this.resultsContentTarget.querySelectorAll('.c-tag').forEach((el) => {
        if (this.selectedValues.includes(el.getAttribute('data-value'))) {
          el.parentNode.removeChild(el)
        }
      })
    }

    if (this.hasSelectedContentTarget) {
      this.selectedContentTarget.querySelectorAll('.c-tag').forEach((el) => {
        if (this.selectedValues.includes(el.getAttribute('data-value'))) {
          el.classList.add('is-active')
        } else {
          el.classList.remove('is-active')
        }
      })
    }
  }

  /**
   * Genera il template HTML di un tag.
   * @param {*} name 
   */
  _templateTag(name) {
    const classes = this.selectedValues.includes(name) ? 'is-active' : ''
    return `<a class="c-tag ${classes}" data-value="${name}" data-action="click->inputtaxonomies#toggleTag">${name}</a>`
  }
  
  /**
   * Genera il template HTML di un area vuota.
   * @param {*} label 
   */
  _templateEmpty(label) {
    return `<a class="c-tag-empty">${label}</a>`
  }

}

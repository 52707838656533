import { BaseController } from '../utils/stimulus'

/**
 * @class AccordionsController
 */
export default class extends BaseController {

  static targets = [
    'item'
  ]

  toggle(e) {
    e.preventDefault()
    this._toggle(parseInt(e.currentTarget.getAttribute('data-item')))
  }

  _toggle(index) {
    this.itemTargets.forEach((item, i) => {
      if (i == index && !item.classList.contains('is-open')) {
        item.classList.add('is-open')
      } else {
        item.classList.remove('is-open')
      }
    })
  }

}

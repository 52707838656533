import { BaseController } from '../utils/stimulus'
import { enableScroll, disableScroll } from '../utils/helpers'

/**
 * @class BottombarController
 */
export default class extends BaseController {

  static targets = [
    'actions'
  ]

  toggleActions(e) {
    e.preventDefault()
    if (this.actionsTarget.classList.contains('is-open'))
      this.closeActions(e)
    else
      this.openActions(e)
  }

  openActions(e) {
    e.preventDefault()
    disableScroll()
    this.actionsTarget.classList.add('is-open')
  }

  closeActions(e) {
    e.preventDefault()
    enableScroll()
    this.actionsTarget.classList.remove('is-open')
  }

}

/**
 * Variabile che definisce se per la navigazione viene usato o meno Turbo.
 */
import "@hotwired/turbo-rails"
export const useTurbo = true

/**
 * L'evento da utilizzare al dom caricato.
 * NOTE: Su js inline (nel DOM tramite tag <script>) si può usare tranquillamente 'DOMContentLoaded'.
 */
export const DOMreadyEvent = useTurbo ? 'turbo:load' : 'DOMContentLoaded'

/**
 * Esegue la navigazione ad una pagina.
 */
export function goTo(url, forceWithoutTurbo = false) {
  if (useTurbo && !forceWithoutTurbo) {
    Turbo.visit(url)
  } else {
    window.location.replace(url)
  }
}

/**
 * Esegue la navigazione alla pagina precedente.
 */
export function goBack() {
  window.history.go(-1)
}

/**
 * Esegue la pulizia della cache di navigazione.
 * Funzione da eseguire quando avviene un cambio di stato per cui è meglio resettare la cache
 * di navigazione (esempio: submit di un form).
 */
export function clearCache() {
  if (useTurbo) {
    Turbo.clearCache()
  }
}
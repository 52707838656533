import Cookies from 'js-cookie'
import { BaseController } from '../utils/stimulus'
import { postRequest } from '../utils/requests'
import { currentUserUuid } from '../utils/app'

/**
 * @class ChatController
 */
export default class extends BaseController {

  static targets = [
    'item',
    'title',
    'icon',
    'history',
    'input'
  ]

  connect() {
    this.loadUrl = this.data.get('load-url')
    this.sendMessageUrl = this.data.get('send-message-url')

    this.currentUserToSend = Cookies.get('_chatUserUuid')
    if (this.currentUserToSend && currentUserUuid()) {
      this._open(this.currentUserToSend, false)
    } else {
      this.currentUserToSend = null
      Cookies.remove('_chatUserUuid')
    }
  }

  open(e) {
    e.preventDefault()

    const userUuid = e.currentTarget.getAttribute('data-chat-user-uuid')

    this._open(userUuid, true)
  }

  close(e) {
    e.preventDefault()
    this._close()
  }

  toggle(e) {
    e.preventDefault()
    this._toggle()
  }

  send(e) {
    e.preventDefault()

    this._send(this.inputTarget.value)
  }

  _toggle() {
    if (this.itemTarget.classList.contains('is-open')) {
      this._open(this.currentUserToSend, true)
    } else {
      this._open(this.currentUserToSend, false)
    }
  }

  _close() {
    Cookies.remove('_chatUserUuid')
    this.currentUserToSend = null

    this.itemTarget.classList.remove('is-open')
    this.itemTarget.classList.remove('is-open-full')
  }

  _open(userUuid, full = false) {
    this.currentUserToSend = userUuid
    Cookies.set('_chatUserUuid', userUuid)

    postRequest(this.loadUrl, { user_to_send_uuid: this.currentUserToSend }, (response) => {
      if (!response.result) {
        Cookies.remove('_chatUserUuid')
        this.getFlash().error(response.errors[0]?.message ? response.errors[0].message : 'Si è verificato un errore', true)
        return
      }

      const { data, messages, validity } = response.payload
      this._updateUi(data, messages, validity)

      if (full) {
        this.itemTarget.classList.add('is-open-full')
        this.itemTarget.classList.remove('is-open')
      } else {
        this.itemTarget.classList.remove('is-open-full')
        this.itemTarget.classList.add('is-open')
      }

      if (!this.currentListener) {
        this.currentListener = true
        

        window.$intervals.push(setInterval(() => {
          if (!this.currentUserToSend) return
  
          postRequest(this.loadUrl, { user_to_send_uuid: this.currentUserToSend }, (response) => {
            if (!response.result) return
  
            const { data, messages, validity } = response.payload
            this._updateUi(data, messages, validity)
          })
        }, 45000))
      }
      
    })
  }

  _send(body) {
    if (!body || this._sendRunning) return
    this._sendRunning = true

    postRequest(this.sendMessageUrl, { user_to_send_uuid: this.currentUserToSend, body }, (response) => {
      this._sendRunning = false

      if (!response.result) {
        this.getFlash().error(response.errors[0]?.message ? response.errors[0].message : 'Si è verificato un errore', true)
        return
      }

      const { data, messages, validity } = response.payload
      this._updateUi(data, messages, validity)

      this.inputTarget.value = ''
    })
  }

  _updateUi(data, messages, validity = { is_valid: true }) {
    const currentUserUuidValue = currentUserUuid()

    this.titleTarget.innerHTML = data.title
    this.iconTarget.setAttribute('style', `background-image: url(${data.icon})`)
    this.inputTarget.disabled = !validity.is_valid

    if (validity.is_valid) {
      this.historyTarget.innerHTML = messages.reverse().map((message) => {
        return `
        <div class="message ${message.user_uuid == currentUserUuidValue ? 'mine' : 'other'}">
          <span>${message.body}</span>
        </div>
        `
      }).join('')
      setTimeout(() => this.historyTarget.scrollTop = this.historyTarget.scrollHeight, 50)
    } else {
      this.historyTarget.innerHTML = `
        <div class="alert">
          Per scrivere un messaggio in chat devi prima <b>diventare fan</b> del creator<br><br>
          <a class="c-button c-button--action" href="${validity.checkout_path}">Diventa fan</a>
        </div>
      `
    }
  }

}

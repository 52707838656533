import { Application, Controller } from 'stimulus'
import { isDebugEnv } from './app'


/**
 * Oggetto applicazione stimulus.
 */
export const application = Application.start()

/**
 * Ritorna l'istanza di un controller identificata tramite il suo ID.
 */
export function getControllerInstanceById(id, name) {
  const instance = application.getControllerForElementAndIdentifier(document.getElementById(id), name)
  return instance
}

/**
 * Ritorna l'istanza di un controller identificata tramite il elemento.
 */
export function getControllerInstanceByEl(el, name) {
  const instance = application.getControllerForElementAndIdentifier(el, name)
  return instance
}

/**
 * Classe di default per tutti i controller dell'applicazione.
 * NOTE: Qui sono presenti eventuali funzioni condivise.
 */
export class BaseController extends Controller {

  /**
   * @function getFlash
   * Ritorna una istanza del controller Flash che, essendo generico per tutti, può essere utilizzato per generare messaggi.
   */
  getFlash() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById('flash'), 'flash')
  }

  /**
   * @function lazyUpdate
   * Esegue un aggiornamento del lazyload delle immagini.
   * Dato che il lazyload non è gestito da un controller può essere comodo in seguito ad un aggiornamento contenuti in ajax. 
   */
  lazyUpdate() {
    if (window.$lazyload) window.$lazyload.update()
  }

  /**
   * @function log
   * Esegue il log di un dato in console. Si occupa di valutare se eseguire o meno il log in base all'environment.
   */
  log(data) {
    if (!isDebugEnv()) return
    console.log(`${this.identifier}Controller ->`, data)
  }

}

/**
 * @function disableScroll
 */
export function disableScroll(el = document.body) {
  el.style.overflowY = 'hidden'
}

/**
 * @function enableScroll
 */
export function enableScroll(el = document.body) {
  el.style.overflowY = 'auto'
}

/**
 * @function getNextElementFromArray
 * @param {*} array 
 * @param {*} current 
 */
export function getNextElementFromArray(array, current) {
  const index = array.indexOf(current)
  if(index >= 0 && index < array.length - 1) {
    return array[index + 1]
  }
}

/**
 * @function getPrevElementFromArray
 * @param {*} array 
 * @param {*} current 
 */
export function getPrevElementFromArray(array, current) {
  const index = array.indexOf(current)
  if(index >= 1) {
    return array[index - 1]
  }
}

/**
 * @function debounce
 * @param {*} func 
 * @param {*} wait 
 * @param {*} immediate 
 */
export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};
// import Flickity from 'flickity' // IMPORTATO DA CDN
import { BaseController } from '../utils/stimulus'

/**
 * @class SliderController
 * Gestisce buona parte degli slider del sito.
 */
export default class extends BaseController {

  static targets = [
    'slides', // Identifica il contenitore di tutte le slides dello slider.
    'slide' // Identifica le singole slide (necessario solo se si usa paralasse).
  ]

  connect() {
    super.connect()
    if (!this.hasSlidesTarget) return

    this.flickity = new Flickity(this.slidesTarget, {
      draggable: true,
      wrapAround: !!this.data.get('infinite'),
      autoPlay: this.data.get('autoplay') || false,
      cellAlign: this.data.get('align') || 'center',
      adaptiveHeight: !!this.data.get('adaptiveheight') || false,
      fullscreen: !!this.data.get('fullscreen') || false,
      pageDots: !!this.data.get('dots') || false,
      prevNextButtons: !!this.data.get('navs') || false,
      imagesLoaded: true
    })

    if (this.data.get('parallax')) {
      const slides = this.slideTargets
      this.flickity.on('scroll', () => {
        this.flickity.slides.forEach((slide, i) => {
          var image = slides[i]
          var x = (slide.target + this.flickity.x) * -1/3
          image.style.backgroundPosition = x + 'px center'
        })
      })
    }
  }

  disconnect() {
    if (this.flickity) this.flickity.destroy()
  }

  _goTo(index) {
    this.flickity.select(index)
  }

}

import { BaseController } from '../utils/stimulus'
import { getRequest } from '../utils/requests'
import { isMobile } from '../utils/dom'
import { debounce } from '../utils/helpers'

/**
 * @class HeaderController
 */
export default class extends BaseController {

  static targets = [
    'menu',
    'notifications',
    'notificationsContent',
    'search',
    'searchInput',
    'searchResults',
    'tags',
    'overlay',
    'header'
  ]

  connect() {
    this.mobile = isMobile()
    this.lastScroll = 0

    this.searchTarget.addEventListener('mouseenter', () => {
      this.tagsTarget.classList.add('is-active')
    })

    this._suggestSearch = debounce(this._suggestSearch, 300)
  }

  closeAll(e) {
    e.preventDefault()
    this.closeMenu(e)
    this.closeNotifications(e)
    this.closeSearch(e)
    this.closeTags(e)
  }

  onScroll(e) {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop

    if (currentScroll > 0 && this.lastScroll <= currentScroll) {
      this.lastScroll = currentScroll
      if (!this.mobile && currentScroll > 100 && !document.body.classList.contains('is-header-hidden')) {
        document.body.classList.add('is-header-hidden')
        this.closeAll(e)
      }
    } else {
      this.lastScroll = currentScroll
      if (!this.mobile && document.body.classList.contains('is-header-hidden')) {
        document.body.classList.remove('is-header-hidden')
      }
    }
  }

  /** MENU */

  toggleMenu(e) {
    e.preventDefault()
    if (this.menuTarget.classList.contains('is-open'))
      this.closeMenu(e)
    else
      this.openMenu(e)
  }

  openMenu(e) {
    e.preventDefault()
    this.closeAll(e)
    this.menuTarget.classList.add('is-open')
    this.overlayTarget.classList.add('is-open')
  }

  closeMenu(e) {
    e.preventDefault()
    this.menuTarget.classList.remove('is-open')
    this.overlayTarget.classList.remove('is-open')
  }
  
  /** NOTIFICATIONS */

  toggleNotifications(e) {
    e.preventDefault()
    if (!this.hasNotificationsTarget) return

    if (this.notificationsTarget.classList.contains('is-open'))
      this.closeNotifications(e)
    else
      this.openNotifications(e)
  }

  openNotifications(e) {
    e.preventDefault()
    if (!this.hasNotificationsTarget) return

    getRequest(this.data.get('notifications-url'), {}, (response) => {
      if (!response.result) return
      
      this.closeAll(e)
      this.notificationsContentTarget.innerHTML = response.payload.template
      this.notificationsTarget.classList.add('is-open')
      this.overlayTarget.classList.add('is-open')
    })
  }

  closeNotifications(e) {
    e.preventDefault()
    if (!this.hasNotificationsTarget) return

    this.notificationsTarget.classList.remove('is-open')
    this.overlayTarget.classList.remove('is-open')
  }

  /** SEARCH */

  toggleSearch(e) {
    e.preventDefault()
    if (this.searchTarget.classList.contains('is-open'))
      this.closeSearch(e)
    else
      this.openSearch(e)
  }

  openSearch(e) {
    e.preventDefault()
    this.closeAll(e)
    this.searchTarget.classList.add('is-open')
    this.overlayTarget.classList.add('is-open')
    setTimeout(() => this.searchInputTarget.focus(), 500)
  }

  closeSearch(e) {
    e.preventDefault()
    this.searchTarget.classList.remove('is-open')
    this.overlayTarget.classList.remove('is-open')
    this.searchResultsTarget.innerHTML = ''
  }

  suggestSearch(e) {
    this._suggestSearch(e.target.value)
  }
  _suggestSearch(value) {
    if (!value) {
      this.searchResultsTarget.innerHTML = ''
      return
    }

    getRequest(this.data.get('searches-url'), { search: value }, (response) => {
      if (!response.result) return

      this.searchResultsTarget.innerHTML = ''
      response.payload.searches.forEach((search) => {
        const link = document.createElement('a')
        link.setAttribute('data-action', 'click->header#clickSearch')
        link.setAttribute('data-search', search)
        link.innerHTML = search
        this.searchResultsTarget.appendChild(link)
      })
    })
  }

  clickSearch(e) {
    e.preventDefault()
    this.searchInputTarget.value = e.target.getAttribute('data-search')
    this.searchTarget.submit()
  }

  /** TAGS */

  toggleTags(e) {
    e.preventDefault()
    if (!this.hasTagsTarget) return

    if (this.tagsTarget.classList.contains('is-open'))
      this.closeTags(e)
    else
      this.openTags(e)
  }

  openTags(e) {
    e.preventDefault()
    if (!this.hasTagsTarget) return

    this.closeAll(e)
    this.tagsTarget.classList.add('is-open')
  }

  closeTags(e) {
    e.preventDefault()
    if (!this.hasTagsTarget) return

    this.tagsTarget.classList.remove('is-open')
  }
}

/**
 * Funzione che ritorna la versione su cui e' in esecuzione l'applicativo.
 */
export function version() {
  if (window.$_appVersion) return window.$_appVersion

  const metaAppVersion = document.querySelector('meta[name="app-version"]')
  if (metaAppVersion) window.$_appVersion = metaAppVersion.getAttribute('content')

  return window.$_appVersion
}

/**
 * Funzione che ritorna l'ambiente su cui e' in esecuzione l'applicativo.
 */
export function env() {
  if (window.$_appEnv) return window.$_appEnv

  const metaAppEnv = document.querySelector('meta[name="app-env"]')
  if (metaAppEnv) window.$_appEnv = metaAppEnv.getAttribute('content')

  return window.$_appEnv
}

/**
 * Funzione che ritorna le stringhe tradotte necessarie al js dell'applicativo.
 * NOTE: Per aggiungere stringhe vedere funzione locales_for_js in LocalesHelper.
 */
 export function locales() {
  if (window.$_appLocales) return window.$_appLocales

  const metaAppEnv = document.querySelector('meta[name="app-locales"]')
  if (metaAppEnv) window.$_appLocales = JSON.parse(metaAppEnv.getAttribute('content'))

  return window.$_appLocales
}

/**
 * Funzione che ritorna l'user uuid dell'utente connesso.
 */
export function currentUserUuid() {
  if (window.$_appCurrentUserUuid) return window.$_appCurrentUserUuid

  const metaAppCurrentUserUuid = document.querySelector('meta[name="app-current-user-uuid"]')
  if (metaAppCurrentUserUuid) window.$_appCurrentUserUuid = metaAppCurrentUserUuid.getAttribute('content')

  return window.$_appCurrentUserUuid
}

/**
 * Funzione che specifica se l'ambiente su cui è in esecuzuone l'applicativo
 * è un ambiente di debug.
 */
export function isDebugEnv() {
  return env() != 'production'
}
import { BaseController } from '../utils/stimulus'
import { getRequest } from '../utils/requests'

/**
 * @class FeedgridController
 */
export default class extends BaseController {

  static targets = [
    'content',
    'loading',
    'pagination'
  ]

  connect() {
    this._loadData(1)
  }

  loadNextPage(e) {
    e.preventDefault()
    this._loadData(this.page + 1)
  }

  _loadData(page) {
    if (this._isLoadingData) return
    this._isLoadingData = true
    this._showLoading()

    getRequest(this.data.get('url'), {
      page: page,
      type: this.data.get('type'),
      user_uuid: this.data.get('user')
    }, (response) => {
      this._isLoadingData = false

      if (this.page && this.page + 1 === page) {
        this.contentTarget.innerHTML += response.payload.template
      } else {
        this.contentTarget.innerHTML = response.payload.template
      }
      this.page = page


      this.lazyUpdate()
      this._hidePagination(response.payload.has_next_page)
      this._hideLoading()
    })
  }

  _showLoading() {
    this.loadingTarget.classList.add('is-active')
  }

  _hideLoading() {
    this.loadingTarget.classList.remove('is-active')
  }

  _hidePagination(hasNext) {
    if (!hasNext) this.paginationTarget.style.display = 'none'
  }

}

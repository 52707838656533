import { BaseController } from '../utils/stimulus'

/**
 * @class FlashController
 */
export default class extends BaseController {

  static targets = [
    'message'
  ]

  success(message, autoclose = false) { this.open(message, 'success', autoclose) }
  error(message, autoclose = false)   { this.open(message, 'error', autoclose) }
  warning(message, autoclose = false) { this.open(message, 'warning', autoclose) }

  open(message, type, autoclose = false) {
    if (this._autocloseTimeout) clearTimeout(this._autocloseTimeout)
    this.messageTarget.innerHTML = message
    this.element.classList.add(`is-${type}`)
    this.element.classList.add('is-active')

    if (autoclose) {
      this._autocloseTimeout = setTimeout(() => this.close(), 3000)
    }
  }

  close() {
    this.element.classList.remove('is-active')
  }

}

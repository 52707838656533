import { DirectUpload } from '@rails/activestorage'
import { BaseController } from '../utils/stimulus'
import { enableScroll, disableScroll } from '../utils/helpers'
import { createElement } from '../utils/dom'

const ACCEPTER_FORMATS_MAP = {
  'image/jpeg': '.jpeg',
  'image/jpg': '.jpg',
  'image/png': '.png',
  'video/mp4': '.mp4',
  'video/quicktime': '.mov'
}

/**
 * @class FileloaderController
 * Gestisce i loader di file e documenti verso il server.
 * NOTE: Per com'e' attualmente strutturato il codice ci si aspetta
 * che ci sia un unico Fileloader nel DOM.
 */
export default class extends BaseController {

  static targets = [
    'input', // identifica l'input file usato per la selezione dei file
    'history', // identifica il contenitore dello storico dei file caricati
    'empty', // identifica il contenitore del messaggio che avvisa l'assenza di file
    'confirm', // identifica il pulsante di conferma del caricamento
    'addfile', // identifica il pulsante di aggiungi file
    'infos', // messaggio informativo dell'input
  ]

  connect() {
    super.connect()
    this._setOptions()
    this._listenActivators()
  }
  
  /**
   * Inizia l'esecuzione del fileloader.
   */
  run(e) {
    e.preventDefault()

    // estraggo i file dall'input in base alla configurazione impostata
    const files = []
    for (let i = 0; i < this.options.maxFiles; i++) {
      if(this.inputTarget.files[i]) {
        files.push(this.inputTarget.files[i])
      }
    }

    // eseguo il caricamento dei vari file
    this.addfileTarget.setAttribute('disabled', true)
    this.inputTarget.setAttribute('disabled', true)
    this.confirmTarget.setAttribute('disabled', true)
    Promise.all(files.map((file) => this._uploadFile(file))).then(() => {
      if (this.options.maxFiles > 1) {
        this.addfileTarget.removeAttribute('disabled')
      }

      this.confirmTarget.removeAttribute('disabled')
      this.inputTarget.removeAttribute('disabled')
    })
  }

  /**
   * Ferma e annulla l'esecuzione del fileloader.
   */
  reset(e) {
    e.preventDefault()
    this._closeFileloader()
    this._resetFileloader()
    this._resetOptions()
  }

  /**
   * Conferma l'esecuzione del fileloader.
   */
  confirm(e) {
    e.preventDefault()

    this._closeFileloader()
    this._resetFileloader()
    this._confirmOptions()
  }

  // GESTIONE OPZIONI
  //////////////////////////////////////////////////////////////////////////////////////
  
  _setOptions(options = {}) {
    const optionsDefault = { maxFiles: 1, maxSize: null, acceptedFormats: null, crop: false, onConfirm: () => {}, _values: [] }
    this.options = Object.assign({}, optionsDefault, options)

    // aggiorno il target input a seconda dell'opzione impostata
    if (this.options.maxFiles > 1) {
      this.inputTarget.setAttribute('multiple', 1)
    } else {
      this.inputTarget.removeAttribute('multiple')
    }

    // aggiorno il testo di info
    let infoText = ''
    if (this.options.maxFiles > 1) {
      infoText += `Max <b>${this.options.maxFiles} files</b>. `
    }
    if (this.options.acceptedFormats) {
      infoText += `Accepted formats: <b>${this.options.acceptedFormats.map((f) => ACCEPTER_FORMATS_MAP[f] || f).join(', ')}</b>. `
    }
    if (this.options.maxSize) {
      infoText += `Max size: <b>${this.options.maxSize}MB</b>. `
    }
    this.infosTarget.innerHTML = infoText
  }

  _resetOptions() {
    this._setOptions()
  }

  _confirmOptions() {
    this.options.onConfirm(this.options._values)
    this._resetOptions()
  }

  // GESTIONE ACTIVATOR
  //////////////////////////////////////////////////////////////////////////////////////

  /**
   * @function _listenActivators
   * Funzione che attiva gli ascoltatori degli elementi del DOM che attivano il
   * fileloader.
   */
  _listenActivators() {
    // NOTE: Modificare la query nel caso in cui sia necessario gestire multipli fileloader nella stessa pagina.
    const activators = document.querySelectorAll('[fileloader]') 
    activators.forEach((fileloader) => {
      this._listenActivator(fileloader)
    })
  }
  
  /**
   * @function _listenActivator
   * Funzione che attiva un singolo ascoltatore che ativa il fileloader.
   */
  _listenActivator(fileloader) {
    const action = fileloader.querySelector('[fileloader-action]')
    const input = fileloader.querySelector('[fileloader-input]')
    const label = fileloader.querySelector('[fileloader-label]')
    const multiple = fileloader.getAttribute('data-fileloader-multiple')
    const maxsize = fileloader.getAttribute('data-fileloader-maxsize')
    const formats = fileloader.getAttribute('data-fileloader-formats')
    const autoload = fileloader.getAttribute('data-fileloader-autoload')
    const crop = fileloader.getAttribute('data-fileloader-crop')
    const form = fileloader.getAttribute('data-fileloader-form')
    const type = fileloader.getAttribute('data-fileloader-type') || 'default'

    const options = {}
    options.onConfirm = (values) => {
      input.value = values.join(',')
      if (label) {
        label.innerHTML = this.data.get('activator-selected').replace('???', values.length)

        if (label.classList.contains('c-button') && values.length) {
          label.classList.remove('c-button--outline')
        }
      }

      if (form) {
        const formInstance = this.application.getControllerForElementAndIdentifier(fileloader, 'form')
        if (formInstance) formInstance.run()
      }
    }
    if (multiple) { options.maxFiles = parseInt(multiple) }
    if (maxsize) { options.maxSize = parseInt(maxsize) }
    if (formats) { options.acceptedFormats = formats.split(',') }
    if (autoload) { options.autoload = autoload == '1' }
    if (type) { options.type = type }
    if (crop) { options.crop = crop.split(':') }

    action.addEventListener('click', (e) => {
      e.preventDefault()
      this._setOptions(options)
      this._openFileloader()
    })
  }

  // GESTIONE FILELOADER
  //////////////////////////////////////////////////////////////////////////////////////

  /**
   * @function _openFileloader
   * Funzione che apre il fileloader.
   */
  _openFileloader() {
    disableScroll()
    this.element.classList.add('is-open')

    if (this.options.autoload) {
      this.inputTarget.click()
    }
  }

  /**
   * @function _closeFileloader
   * Funzione che apre il fileloader.
   */
  _closeFileloader() {
    enableScroll()
    this.element.classList.remove('is-open')
  }

  /**
   * @function _resetFileloader
   * Funzione che esegue il reset del fileloader.
   */
  _resetFileloader() {
    // reset input
    this.inputTarget.value = null
    this.inputTarget.removeAttribute('disabled')

    // reset confirm
    this.confirmTarget.removeAttribute('disabled')

    // reset addfile
    this.addfileTarget.removeAttribute('disabled')

    // reset history
    this.historyTarget.innerHTML = ''
    this.historyTarget.style.display = 'none'

    // reset empty
    this.emptyTarget.style.display = 'block'
  }

  // GESTIONE PROCESSO DI UPLOAD
  //////////////////////////////////////////////////////////////////////////////////////

  /**
   * @function _uploadFile
   * Esegue il cariamento di un singolo file verso il server.
   */
  _uploadFile(file) {
    return new Promise(async (resolve, reject) => {
      // se presente opzione crop e file è una immagine, eseguo il crop
      if (this.options.crop && file.type.includes('image')) {
        const newBlob = await this._cropFile(file)
        const newFile = new File([newBlob], file.name, { type: file.type })
        file = newFile
      }

      // nascondo messaggio empty e mostro lo storico
      this.emptyTarget.style.display = 'none'
      this.historyTarget.style.display = 'block'

      // preparo gli elementi del dom history che mostrano lo stato dell'upload
      const [history, { historyError, historyLog, historyPercentage, historyUp, historyDown }] = this._createHistoryItem(file)
      this.historyTarget.appendChild(history)

      // eseguo la validazione del file per segnalare subito eventuali errori
      if (this.options.maxSize && this.options.maxSize < (file.size / 1000000)) {
        historyError.innerHTML = this.data.get('error-maxsize').replace('???', `${this.options.maxSize}MB`)
        history.classList.add('is-error')
        return resolve()
      }
      if (this.options.acceptedFormats && !this.options.acceptedFormats.includes(file.type)) {
        historyError.innerHTML = this.data.get('error-acceptedformats').replace('???', `${this.options.acceptedFormats.join(',')}`)
        history.classList.add('is-error')
        return resolve()
      }

      historyLog.innerHTML = 'starting..'

      // inizializzo l'upload e gestisco il suo avanzamento
      const upload = new DirectUpload(file, `${this.data.get('url')}?type=${this.options.type}`, {
        directUploadWillStoreFileWithXHR: (request) => {
          history.classList.add('is-uploading')
          historyLog.innerHTML = 'uploading..'

          request.upload.addEventListener("progress", (e) => {
            const percentage = Math.round(e.loaded * 100 / e.total)
            if (percentage >= 100) {
              historyPercentage.innerHTML = ''
            } else {
              historyPercentage.innerHTML = `${percentage}%` 
            }
          })
        }
      })
      
      // eseguo l'upload e gestisco il suo completamento
      upload.create((error, blob) => {
        history.classList.remove('is-uploading') // tolgo classe di upload in corso

        // gestisco caso di errore
        if (error) {
          console.log(error)
          historyError.innerHTML = this.data.get('error-upload')
          historyLog.innerHTML = ''
          history.classList.add('is-error')
          return resolve()
        }

        // gestisco caso positivo
        historyLog.innerHTML = 'completed upload!'
        history.classList.add('is-uploaded')
        history.id = blob.signed_id
        this.options._values.push(blob.signed_id)

        historyUp.addEventListener('click', () => {
          // update sort order on this.options._values
          const currentIndex = this.options._values.indexOf(blob.signed_id)
          if (currentIndex > 0) {
            const previousIndex = currentIndex - 1
            const previousId = this.options._values[previousIndex]
            this.options._values[previousIndex] = blob.signed_id
            this.options._values[currentIndex] = previousId
          } else {
            this.options._values.splice(currentIndex, 1)
            this.options._values.push(blob.signed_id)
          }

          // update historyTarget order based on this.options._values
          const historyItems = Array.from(this.historyTarget.children)
          historyItems.sort((a, b) => {
            return this.options._values.indexOf(a.id) - this.options._values.indexOf(b.id)
          })
          this.historyTarget.innerHTML = ''
          historyItems.forEach((item) => {
            this.historyTarget.appendChild(item)
          }) 
        })

        historyDown.addEventListener('click', () => {
          // update sort order on this.options._values
          const currentIndex = this.options._values.indexOf(blob.signed_id)
          if (currentIndex < this.options._values.length - 1) {
            const nextIndex = currentIndex + 1
            const nextId = this.options._values[nextIndex]
            this.options._values[nextIndex] = blob.signed_id
            this.options._values[currentIndex] = nextId
          } else {
            this.options._values.splice(currentIndex, 1)
            this.options._values.unshift(blob.signed_id)
          }

          // update historyTarget order based on this.options._values
          const historyItems = Array.from(this.historyTarget.children)
          historyItems.sort((a, b) => {
            return this.options._values.indexOf(a.id) - this.options._values.indexOf(b.id)
          })
          this.historyTarget.innerHTML = ''
          historyItems.forEach((item) => {
            this.historyTarget.appendChild(item)
          })
        })

        resolve()
      })
    })
  }

  _createHistoryItem(file) {
    const isFileImage = file.type.includes('image')

    const item = createElement('div', 'fileloaderHistory')

    const preview = createElement('div', 'fileloaderHistoryPreview')
    const previewImage = createElement('img', 'fileloaderHistoryPreviewImage')
    previewImage.src = isFileImage ? URL.createObjectURL(file) : '/icon.png'
    preview.appendChild(previewImage)
    const previewStatus = createElement('div', 'fileloaderHistoryPreviewStatus', '...')
    preview.appendChild(previewStatus)
    item.appendChild(preview)

    const infos = createElement('div', 'fileloaderHistoryInfos')
    const infosName = createElement('span', 'fileloaderHistoryInfosName', file.name)
    infos.appendChild(infosName)
    const infosError = createElement('span', 'fileloaderHistoryInfosError')
    infos.appendChild(infosError)
    const infosLog = createElement('span', 'fileloaderHistoryInfosLog')
    infos.appendChild(infosLog)
    item.appendChild(infos)
    

    const position = createElement('div', 'fileloaderHistoryPosition')
    const positionUp = createElement('button', 'fileloaderHistoryPositionUp', '')
    positionUp.classList.add('fileloaderHistoryPositionButton')
    position.appendChild(positionUp)
    const positionDown = createElement('button', 'fileloaderHistoryPositionDown', '')
    positionDown.classList.add('fileloaderHistoryPositionButton')
    position.appendChild(positionDown)
    item.appendChild(position)
    

    return [item, {
      historyName: infosName,
      historyError: infosError,
      historyLog: infosLog,
      historyPercentage: previewStatus,
      historyUp: positionUp,
      historyDown: positionDown
    }]
  }

  // CROPPER
  //////////////////////////////////////////////////////////////////////////////////////

  _cropFile(file) {
    return new Promise((resolve, reject) => {
      let cropper = document.getElementById('fileloaderCropper')
      if (cropper) cropper.remove()

      cropper = createElement('div', 'fileloaderCropper')
      cropper.id = 'fileloaderCropper'
      cropper.innerHTML = `
      <div>
        <img src="${URL.createObjectURL(file)}" style="display: none;" />
        <button class="c-button c-button--action">Confirm</button>
      </div>
      `
      document.body.appendChild(cropper)

      let currentImageBlob = null
      const cropperInstance = new Cropper(cropper.querySelector('img'), {
        aspectRatio: this.options.crop[0] / this.options.crop[1],
        viewMode: 1,
        crop: (e) => {
          const canvas = cropperInstance.getCroppedCanvas()
          canvas.toBlob((blob) => {
            currentImageBlob = blob
          })
        }
      })

      const button = cropper.querySelector('button')
      button.addEventListener('click', () => {
        cropperInstance.destroy()
        cropper.remove()
        resolve(currentImageBlob)
      })
    })
  }

}

import { BaseController } from '../utils/stimulus'

/**
 * @class VideoplayerController
 */
export default class extends BaseController {

  static targets = [
    'player'
  ]

  connect() {
    if (this.hasPlayerTarget) {
      const player = Stream(this.playerTarget)

      if (this.data.get('autoplay')) {
        player.play().catch(() => {
          player.muted = true
          player.play()
        })
      }
    }
  }

}

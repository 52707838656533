import { BaseController } from '../utils/stimulus'
import { getRequest } from '../utils/requests'
import { enableScroll, disableScroll, getPrevElementFromArray, getNextElementFromArray } from '../utils/helpers'
import { locales } from '../utils/app'

/**
 * @class StoryviewerController
 * Gestisce la visualizzazione delle stories.
 */
export default class extends BaseController {

  static targets = [
    'content',
    'loading',
    'cta',
    'ctalabel',
    'model',
    'modellabel',
    'modelimage',
    'navs',
    'name'
  ]
  
  connect() {
    super.connect()
    this._listenActivators()
  }

  close(e) {
    e.preventDefault()
    this._closeStoryviewer()
  }

  goToNext(e) {
    e.preventDefault()
    const next = getNextElementFromArray(this.options.context, this.options.current)
    if (next) {
      this._loadStoryviewerContent(next)
    } else if (this.options.rand) {
      this._loadStoryviewerContent('rand')
    }
  }

  goToPrev(e) {
    e.preventDefault()
    const prev = getPrevElementFromArray(this.options.context, this.options.current)
    if (prev) {
      this._loadStoryviewerContent(prev)
    }
  }

  // GESTIONE OPZIONI
  //////////////////////////////////////////////////////////////////////////////////////
  
  _setOptions(options = {}) {
    const optionsDefault = { current: null, context: [] }
    this.options = Object.assign({}, optionsDefault, options)
  }

  _resetOptions() {
    this._setOptions()
  }

  // GESTIONE ACTIVATOR
  //////////////////////////////////////////////////////////////////////////////////////

  /**
   * @function _listenActivators
   * Funzione che attiva gli ascoltatori degli elementi del DOM che attivano il
   * storyvviewer.
   */
  _listenActivators() {
    const activators = document.querySelectorAll('[storyviewer]') 
    activators.forEach((storyviewer) => {
      this._listenActivator(storyviewer)
    })
  }

  /**
   * @function _listenActivator
   * Funzione che attiva un singolo ascoltatore che ativa il storyviewer.
   */
  _listenActivator(storyviewer) {
    const storyUuid = storyviewer.getAttribute('data-storyviewer-uuid')
    const storyContext = storyviewer.getAttribute('data-storyviewer-context')
    const storyRandom = storyviewer.getAttribute('data-storyviewer-random')

    storyviewer.addEventListener('click', (e) => {
      e.preventDefault()

      const options = {}
      options.current = storyUuid
      if (storyContext) { options.context = storyContext.split(',') }
      if (storyRandom) { options.rand = storyRandom == '1' }
      this._setOptions(options)

      this._resetStoryviewer()
      this._setStoryViewerNav()
      this._openStoryviewer()
      this._loadStoryviewerContent(this.options.current)
    })
  }

  // GESTIONE FILELOADER
  //////////////////////////////////////////////////////////////////////////////////////

  /**
   * @function _openStoryviewer
   * Funzione che apre il fileloader.
   */
  _openStoryviewer() {
    disableScroll()
    this.element.classList.add('is-open')
  }

  /**
   * @function _closeStoryviewer
   * Funzione che chiude il fileloader.
   */
  _closeStoryviewer() {
    enableScroll()
    this.element.classList.remove('is-open')
    this.contentTarget.innerHTML = '' // per bloccare eventuali video che rimangono in background
  }

  /**
   * @function _resetStoryviewer
   * Funzione che ripristina il viewer al suo stato iniziale.
   */
  _resetStoryviewer() {
    this.contentTarget.classList.remove('is-active')
    this.loadingTarget.classList.remove('is-active')
  }

  /**
   * @function _setStoryViewerNav
   * Imposta gli elementi di navigazione dello storyviewer in base alle story del context.
   */
  _setStoryViewerNav() {
    this.navsTarget.innerHTML = ''
    if (!this.options.context) return

    this.options.context.map(() => {
      this.navsTarget.innerHTML = this.navsTarget.innerHTML + '<span></span>'
    })
  }

  /**
   * @function _updateStoryViewerNav
   * Aggiorna lo stato dei simboli di navigazione delle story.
   * @param {*} currentStoryUuid 
   */
  _updateStoryViewerNav(currentStoryUuid) {
    if (!this.options.context) return

    this.navsTarget.querySelectorAll('span').forEach((span, i) => {
      if (i <= this.options.context.indexOf(currentStoryUuid)) {
        span.classList.add('is-active')
      } else {
        span.classList.remove('is-active')
      }
    })
  }
  
  /**
   * @function _updateStoryViewerCta
   * Aggiorna la CTA principale della story in base alla risposta del server.
   * @param {*} responsePayload 
   */
  _updateStoryViewerCta(responsePayload) {
    if (responsePayload.storyviewer_data.visibility) {
      

      if (responsePayload.storyviewer_data.external_link) {
        this.ctalabelTarget.innerHTML = locales()['general.cta.go_to_link']
        this.ctaTarget.href = responsePayload.storyviewer_data.external_link
      } else {
        this.ctalabelTarget.innerHTML = locales()['general.cta.go_to_profile']
        this.ctaTarget.href = responsePayload.storyviewer_data.profile_url
      }
    } else {
      this.ctaTarget.href = responsePayload.storyviewer_data.signup_url
      this.ctalabelTarget.innerHTML = 'Only for Yuzzers'
    }

    this.modelTarget.href = responsePayload.storyviewer_data.profile_url
    this.modellabelTarget.innerHTML = responsePayload.storyviewer_data.profile_username
    this.modelimageTarget.innerHTML = responsePayload.storyviewer_data.profile_image
  }

  /**
   * @function _loadStoryviewerContent
   * Funzione che carica il contenuto di una story dentro lo storyviewer.
   * @param {*} storyUuid 
   */
  _loadStoryviewerContent(storyUuid) {
    if (this._loadStoryviewerContentAppearTimeout1) clearTimeout(this._loadStoryviewerContentAppearTimeout1)
    if (this._loadStoryviewerContentAppearTimeout2) clearTimeout(this._loadStoryviewerContentAppearTimeout2)
    this.ctaTarget.classList.remove('is-active')
    this.contentTarget.classList.remove('is-active')
    this.loadingTarget.classList.add('is-active')

    getRequest(this.data.get('url'), { feed_uuid: storyUuid }, (response) => {
      if (!response.result) return // NOTE: Gestire graficamente il caso di errore

      this.options.current = storyUuid
      this._loadStoryviewerContentAppearTimeout1 = setTimeout(() => {
        this.contentTarget.innerHTML = response.payload.template
        this._updateStoryViewerCta(response.payload)

        this._loadStoryviewerContentAppearTimeout2 = setTimeout(() => {
          this.loadingTarget.classList.remove('is-active')
          this.contentTarget.classList.add('is-active')
          this.ctaTarget.classList.add('is-active')
          this._updateStoryViewerNav(storyUuid)
        }, 500)
      }, 350)
    })
  }

}

import { BaseController } from '../utils/stimulus'

/**
 * @class CopytoController
 */
export default class extends BaseController {

  static targets = [
    'input'
  ]

  connect() {}

  copy(e) {
    e.preventDefault()
    
    this.inputTarget.select()
    this.inputTarget.setSelectionRange(0, 99999) /* For mobile devices */
    navigator.clipboard.writeText(this.inputTarget.value)
    this.getFlash().success(this.data.get('message'), true)
  }

}
